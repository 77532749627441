<template>
  <div class="checkout-header">
    <div class="checkout-header__content desktop-only">
      <a class="checkout-header__content__back" href="/it/cart">
        <MenuBack class="checkout-header__content__back__icon" />
        Torna al carrello
      </a>
      <a class="checkout-header__content__logo" href="/it">
        <ColfertLogo class="checkout-header__content__logo__icon" />
      </a>
      <a class="checkout-header__content__need-help" href="/it/contatti">
        Hai bisogno di aiuto?
      </a>
    </div>
    <div class="checkout-header__content smartphone-only">
      <a class="checkout-header__content__logo" href="/it">
        <ColfertLogo class="checkout-header__content__logo__icon" />
      </a>
      <div class="checkout-header__content__row">
        <a class="checkout-header__content__back" href="/it/cart">
          <MenuBack class="checkout-header__content__back__icon" />
          Torna al carrello
        </a>
        <a class="checkout-header__content__need-help" href="/it/contatti">
          Hai bisogno di aiuto?
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import { MenuBack, ColfertLogo } from '~/components/General/Icons';

export default defineComponent({
  name: 'CheckoutHeader',
  components: { MenuBack, ColfertLogo },
  props: {},
  setup() {},
});
</script>

<style lang="scss" scoped>
.checkout-header {
  background-color: var(--c-primary);
  &__content {
    @include paragraph-regular;
    display: flex;
    align-items: center;
    width: 100vw;
    margin: 0 auto;
    height: 100%;
    @include for-mobile {
      flex-direction: column;
      &__row {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        padding: 0 1.25rem 1.875rem;
      }
    }

    &__back {
      display: flex;
      align-items: center;
      gap: 0.375rem;
      @include pointer;
      &__icon {
        transform: scale(0.8);
      }
      &:hover {
        color: var(--c-black);
      }
    }

    &__logo {
      &__icon {
        transform: scale(0.5);
      }
    }
    &__need-help {
      text-decoration: underline;
      @include pointer;
      &:hover {
        color: var(--c-black);
      }
    }
  }
}

@include from-desktop-min {
  .checkout-header {
    height: 5.3125rem;
    &__content {
      width: 1440px;
      justify-content: space-between;
    }
  }
}
</style>
