<template>
  <div>
    <span class="category-page__counter__number">
      {{ productsCurrentlyInCategory }}
    </span>
    <span class="category-page__counter__text"> prodotti trovati </span>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import { useCategoryPage } from '~/composables';

export default defineComponent({
  name: 'CategoryCounter',
  components: {},
  props: {
    categoryId: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const { productsCurrentlyInCategory } = useCategoryPage(props.categoryId);

    return {
      productsCurrentlyInCategory,
    };
  },
});
</script>

<style lang="scss" scoped>
.category-page__counter {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 1.5rem;
  padding: 1rem 0;
  border-bottom: 1px solid var(--c-black);
  &__number {
    @include text-24x24-black;
  }
  &__text {
    @include text-13x16-regular;
  }
}
@include from-desktop-min {
  .category-page__counter {
    &__number {
      @include text-36x40-black;
    }
    &__text {
      @include text-16x22-regular;
    }
  }
}
</style>
