<template>
  <div class="checkout-footer" v-show="menuItems">
    <div class="checkout-footer__content" v-if="menuItems">
      <nuxt-link
        class="checkout-footer__content__link"
        v-for="(menu, index) in menuItems"
        :key="index"
        :to="menu.link"
      >
        {{ menu.label }}
      </nuxt-link>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from '@nuxtjs/composition-api';
import { useMenu } from '@gemini-vsf/composables';

export default defineComponent({
  name: 'CheckoutFooter',
  components: {},
  props: {},
  setup() {
    const CHECKOUT_FOOTER_MENU_CODE = 'checkout-footer-menu';
    const { menu, search: searchMenu } = useMenu(CHECKOUT_FOOTER_MENU_CODE);
    const menuItems = ref();

    onMounted(async () => {
      await searchMenu({ code: CHECKOUT_FOOTER_MENU_CODE });
      menuItems.value = menu?.value?.items.length > 0 ? menu.value.items : null;
    });

    return { menuItems };
  },
});
</script>

<style lang="scss" scoped>
.checkout-footer {
  background-color: var(--c-grey);
  height: fit-content;
  flex-shrink: 0;
  &__content {
    @include paragraph-regular;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.5rem;
    padding: 0.5rem;
    &__link {
      @include flex-center;
    }
  }
}
@include from-tablet-min {
  .checkout-footer {
    height: 5vh;
    flex-shrink: 0;
    &__content {
      @include paragraph-regular;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      max-width: 1440px;
      margin: 0 auto;
      height: 100%;
    }
  }
}
</style>
