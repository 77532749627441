<template>
  <div class="products">
    <div
      v-if="products && products.length > 0 && !loadingProducts"
      class="products-list-loader"
    >
      <div class="category-products">
        <CategoryOrderBy :category-id="categoryId" />
        <CategorySubHeading :category-id="categoryId" />
        <div class="products-list">
          <CustomProductCard
            v-for="product in products"
            :key="product.uid"
            class="product desktop-product"
            :image="
              getRolloverImage(product)
                ? [getProductThumbnailImage(product), getRolloverImage(product)]
                : getProductThumbnailImage(product)
            "
            :link="localePath(buildProductUrl(product))"
            :title="getProductTitle(product)"
            :image-alt="getProductImageAlt(product, 'thumbnail')"
            :regular-price="$fc(getProductPrice(product).regular)"
            :special-price="$fc(getProductPrice(product).special)"
            :product="product"
            :stock-availability="stockAvailability"
            :npp="(npp && npp[product.merchant_sku]) || false"
          />
        </div>
        <CategoryPagination :category-id="categoryId" />
      </div>
    </div>
    <div v-else-if="loadingProducts" class="products-skeleton">
      <SfSkeleton v-for="n in 14" :key="n" />
    </div>
  </div>
</template>

<script>
import productPlaceholder from '~/static/icons/placeholder-list.svg';
import { productGetters } from '@gemini-vsf/composables';
import {
  defineComponent,
  onMounted,
  ref,
  watch,
} from '@nuxtjs/composition-api';
import {
  useUrlHelpers,
  useUserWishlists,
  useCategoryPage,
  useGeminiApi,
  useUser,
} from '~/composables';
import CustomProductCard from '~/components/CustomProductCard.vue';
import { SfSkeleton } from '@storefront-ui/vue';
import { getProductTitle } from '~/helpers/product/productData';
import CategoryPagination from './CategoryPagination.vue';
import CategoryOrderBy from './CategoryOrderBy.vue';
import CategorySubHeading from './CategorySubHeading.vue';

export default defineComponent({
  name: 'ProductList',
  components: {
    CustomProductCard,
    SfSkeleton,
    CategoryPagination,
    CategoryOrderBy,
    CategorySubHeading,
  },
  props: {
    products: {
      type: Array,
      default: () => [],
    },
    categoryId: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const { buildProductUrl } = useUrlHelpers();
    const { loadItemsFromList, itemsInWishlist } = useUserWishlists();
    const { loadingProducts } = useCategoryPage(props.categoryId);
    const { getStockData, getNppBySkus } = useGeminiApi();
    const stockAvailability = ref(null);
    const npp = ref(null);
    const { user, load: loadUser } = useUser();

    const aggregateStock = (stockData) => {
      if (!stockData) return null;
      const aggregatedStock = {};

      Object.keys(stockData).forEach((sku) => {
        const stockInfo = stockData[sku].stock;
        aggregatedStock[sku] = Object.values(stockInfo).reduce(
          (a, b) => Number(a) + Number(b),
          0
        );
      });

      return aggregatedStock;
    };

    const fetchProductsStock = async () => {
      if (!props.products) return;
      try {
        const variantSkus = props.products.reduce((acc, product) => {
          if (product.list_sku_variants) {
            return [...acc, ...product.list_sku_variants];
          }
          return acc;
        }, []);

        const stockData = await getStockData(variantSkus);
        stockAvailability.value = aggregateStock(stockData);
      } catch (error) {
        console.error('fetchProductsStock ~ error:', error);
      }
    };

    const fetchNppBySkus = async () => {
      if (!props.products) return;
      if (!user.value) {
        await loadUser();
      }
      try {
        const variantSkus = props.products.reduce((acc, product) => {
          if (product.list_sku_variants) {
            return [...acc, ...product.list_sku_variants];
          }
          return acc;
        }, []);
        const nppData = await getNppBySkus(variantSkus, user.value);
        if (!nppData) return;
        npp.value = props.products.reduce((acc, product) => {
          const hasNpp = Object.entries(nppData).find(([variantSku]) =>
            product.list_sku_variants.includes(variantSku)
          )?.[1]?.npp;
          return { ...acc, [product.merchant_sku]: hasNpp };
        }, {});
      } catch (error) {
        console.error('fetchNppBySkus ~ ProductList ~ error:', error);
      }
    };

    const fetchProductsForWishlist = async () => {
      if (props.products) {
        const productSkus = props.products.map((p) => p.sku);
        await loadItemsFromList(productSkus);
      }
    };

    watch(
      () => props.products,
      async (newProducts, oldProducts) => {
        if (!newProducts) return;
        if (JSON.stringify(newProducts) === JSON.stringify(oldProducts)) return;
        await fetchProductsForWishlist();
        await fetchProductsStock();
        await fetchNppBySkus();
      }
    );

    onMounted(async () => {
      await fetchProductsForWishlist();
      await fetchProductsStock();
      await fetchNppBySkus();
    });

    return {
      buildProductUrl,
      getRolloverImage: productGetters.getRolloverImage,
      getProductThumbnailImage: productGetters.getProductThumbnailImage,
      getProductName: productGetters.getName,
      getProductPrice: productGetters.getPrice,
      getProductImageAlt: productGetters.getProductImageAlt,
      getProductTitle,
      itemsInWishlist,
      productPlaceholder,
      loadingProducts,
      stockAvailability,
      npp,
    };
  },
});
</script>

<style lang="scss">
.products-skeleton {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  .sf-skeleton {
    height: 18.75rem;
  }
}

.products-list {
  .sf-product-card {
    .sf-product-card__image-wrapper {
      display: flex;
      flex-direction: column;
      .sf-image--wrapper {
        height: 100%;
      }
      .sf-product-card__link {
        text-align: center;
        flex-grow: 1;
      }
    }
  }

  .yf-custom-product-card {
    .sf-product-card__title {
      margin: 5px auto 0;
    }
    .yf-product-content {
      padding-bottom: 5px;
    }
    .sf-product-card__price {
      margin-top: 5px;
      @media (max-width: 768px) {
        margin-top: 1px;
      }
    }
    .sf-product-card__colors-badge {
      width: 12px;
      height: 12px;
    }
  }
}

.product.yf-custom-product-card .sf-product-card__title {
  margin-top: 20px;
  white-space: normal;
  display: block;
}

@media (max-width: 1023px) {
  .product.yf-custom-product-card .sf-product-card__title {
    line-height: 16px;
  }
  .product:nth-child(5n + 3) {
    &.yf-custom-product-card .sf-product-card__title {
      line-height: normal;
      max-width: unset;
    }
  }

  .products-list {
    .yf-custom-product-card .sf-overlay {
      background: transparent;
    }
    .sf-color-picker__label {
      display: none;
    }
    .yf-custom-product-card .yf-product-content {
      opacity: 1;
      visibility: visible;
    }
  }
}
</style>

<style lang="scss" scoped>
.circle-loader-category-container {
  position: relative;
  height: 50vh;
  @include for-mobile {
    height: 70vh;
  }
  .circle-loader-category {
    width: 50px;
    height: 50px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    border: 5px solid #fff;
    border-top: 5px solid gray;
    animation: loader 1.5s infinite linear;
  }
}

@keyframes loader {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.category-page {
  .product-list-loader {
    min-height: 100vh;
    .sf-loader {
      &__overlay {
        position: absolute;
        min-height: 50vh;
      }
    }
  }
}

.products {
  position: relative;

  #scroll-loader {
    height: 50px;
  }
  .circle-loader {
    width: 50px;
    height: 50px;
    position: absolute;
    bottom: 0%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    border: 5px solid #fff;
    border-top: 5px solid gray;
    animation: loader 1.5s infinite linear;
  }
  .product-list-empty {
    @include heading-h1;
  }
}

@keyframes loader {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.products-list {
  display: grid;
}

.category-products {
  margin: 0 auto;

  a {
    text-decoration-line: none;
  }

  .product-wish {
    opacity: 0;
    position: absolute;
    right: 20px;
    top: 20px;
  }

  p {
    margin: 0 0 10px;

    &:last-child {
      margin: 0;
    }
  }

  .product-content {
    position: relative;
    height: 100%;

    .product-content-image,
    figure {
      height: 100%;
    }

    .product-content-info {
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      align-items: center;
      background: #fff;
      bottom: 0;
      display: -webkit-box;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: center;
      left: 0;
      line-clamp: 2;
      min-height: 100px;
      opacity: 0;
      opacity: 0;
      overflow: hidden;
      padding: 15px 0 15px;
      position: absolute;
      text-align: center;
      text-overflow: ellipsis;
      transition: opacity 0.7s;
      white-space: normal;
      width: 100%;

      .product-info-name {
        max-width: 200px;

        line-height: 24px;
        a {
          font-size: 20px;
        }
      }

      .product-info-price {
        span {
          color: #0b0e0c;
          font-family: var(--font-family);
          font-size: 12px;
          font-weight: 500;
          text-align: center;
        }
      }

      .product-info-color .sf-color-picker {
        position: static;

        button {
          height: 24px;
          margin: 5px;
          width: 24px;
        }
      }
    }

    &:hover {
      .product-content-info,
      .product-wish {
        opacity: 1;
      }
    }
  }

  figure {
    display: flex;
    margin: 0;
  }

  img {
    width: 100%;
  }
}

@media (max-width: 1023px) {
  .category-products .product-content .product-content-info .product-info-name {
    max-width: 200px;
  }

  .product-info-color {
    display: none;
  }

  .products-list {
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
  }

  .category-products {
    .product-content {
      .product-content-info {
        opacity: 1;

        .product-info-name {
          padding: 0;

          a {
            font-size: 16px;
            line-height: 16px;
          }
        }

        .product-info-price {
          font-size: 15px;
        }

        .product-info-color {
          .sf-color-picker button {
            width: 20px;
            height: 20px;
          }
        }
      }
    }

    .product-wish {
      display: none;
    }
  }
}

@media (max-width: 640px) {
  .category-products .product-content .product-content-info {
    padding: 10px 0;

    .product-info-name {
      line-height: 20px;
    }
  }

  .products-list
    .product:nth-child(5n + 3)
    .product-content
    .product-content-info
    .product-info-name
    a {
    font-size: 20px;
  }
}

@media (min-width: 1024px) {
  .products-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 24px;
    grid-template-rows: repeat(auto, auto);
  }
}
</style>
