<template>
  <div class="related-categories" v-show="relatedCategories">
    <h2>{{ $t('Categorie correlate') }}</h2>
    <div class="related-categories-row flex">
      <div
        class="related-categories-col"
        v-for="item in relatedCategories"
        :key="item.uid"
      >
        <nuxt-link :to="localePath(item.link)">
          <nuxt-img src="/icons/search-icon.svg" alt="benefits" />
          {{ $t(item.label) }}
        </nuxt-link>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, computed } from '@nuxtjs/composition-api';
import { useMenu } from '@gemini-vsf/composables';
import { useCategoryPage } from '~/composables';

export default defineComponent({
  props: {
    categoryId: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const { categoryData } = useCategoryPage(props.categoryId);

    const { menu: headerMenuItems } = useMenu('headerMenu');

    const searchChildren = (menuItems, targetLabel) => {
      const foundItem =
        menuItems && menuItems.length > 0
          ? menuItems.find((item) => item.label === targetLabel)
          : [];

      if (foundItem) {
        return foundItem.children;
      }

      const childrenWithResults = menuItems
        .filter((item) => item.children)
        .map((item) => searchChildren(item.children, targetLabel))
        .filter((finalRes) => finalRes !== null);

      return childrenWithResults.length > 0 ? childrenWithResults[0] : null;
    };

    const relatedCategories = computed(() =>
      headerMenuItems.value?.items && categoryData.value?.name
        ? searchChildren(headerMenuItems.value.items, categoryData.value.name)
        : []
    );

    return {
      relatedCategories,
      headerMenuItems,
    };
  },
});
</script>

<style lang="scss" scoped>
.related-categories {
  padding-bottom: 6rem;
  @include to-tablet-1200 {
    padding-bottom: 4rem;
  }
  h2 {
    @include font-italic;
    margin-bottom: 2rem;
    font-weight: normal;
    @include to-tablet-1200 {
      margin-bottom: 1.938rem;
    }
    @include to-mobile-max {
      font-size: var(--font-size-24);
      line-height: var(--line-height-24);
    }
  }
  .related-categories-row {
    display: var(--flex);
    flex-wrap: var(--flex-wrap);
    margin: 0 -0.75rem;
    .related-categories-col {
      width: 25%;
      padding: 0 0.75rem;
      @include to-tablet-1200 {
        width: 50%;
        padding: 0 0.75rem 1.5rem 0.75rem;
      }
      @include to-mobile-max {
        width: 100%;
        padding-bottom: 0.5rem;
      }
      a {
        border: solid 0.063rem var(--c-dark-grey);
        border-top: solid 0.5rem var(--c-yellow);
        display: var(--flex);
        width: var(--w-full);
        padding: 2rem 1.5rem;
        align-items: var(--align-items);
        font-size: var(--font-size-20);
        line-height: var(--line-height-26);
        color: var(--c-dark-grey);
        @include to-mobile-max {
          font-size: var(--font-size-13);
          line-height: var(--line-height-16);
          padding: 1rem;
        }
        img {
          margin-right: 1.5rem;
          @include to-mobile-max {
            margin-right: 1.438rem;
          }
        }
        &:hover {
          border-top: solid 0.5rem var(--c-dark-grey);
        }
      }
    }
  }
  .benefits-block {
    width: 25%;
    display: var(--flex);
    flex-wrap: var(--flex-wrap);
    align-items: var(--align-items);
    position: var(--relative);

    @include to-tablet-max {
      justify-content: var(--justify-center);
      padding-left: 10px;
      padding-right: 10px;
    }

    @include to-mobile-max {
      width: 100%;
      justify-content: start;
      padding: 0 0 48px 0;

      &:last-child {
        padding-bottom: 0;
      }
    }

    &:not(:first-child) {
      padding-left: 32px;

      @include to-tablet-1500 {
        padding-left: 15px;
      }

      @include to-tablet-max {
        padding-left: 10px;
      }

      @include to-mobile-max {
        padding-left: 0;
      }

      &:after {
        content: '';
        position: var(--absolute);
        top: -24px;
        left: var(--left-0);
        height: calc(100% + 48px);
        width: 1px;
        background-color: var(--c-dark-grey);
        @include to-mobile-max {
          height: 1px;
          width: var(--w-full);
        }
      }
    }

    @include to-mobile-max {
      width: var(--w-full);
    }

    p {
      margin-bottom: 0;
    }

    .benefits-points {
      max-width: 213px;
      font-family: var(--lato-regular);
      font-size: var(--font-size-16);
      line-height: var(--line-height-20);
      font-weight: var(--font-weight-bold);
      padding-left: 32px;

      @include to-tablet-1500 {
        padding-left: 15px;
        max-width: 163px;
      }

      @include to-tablet-max {
        padding-left: 0;
        text-align: center;
      }

      @include to-mobile-max {
        font-size: var(--font-size-13);
        line-height: var(--line-height-16);
        max-width: 100%;
        text-align: left;
        padding-left: 32px;
        width: calc(100% - 50px);
      }

      .available-items {
        font-family: var(--lato-regular);
        font-size: var(--font-size-20);
        line-height: var(--line-height-24);
      }
    }

    &.benefits-content {
      p {
        font-family: var(--lato-regular);
        font-size: var(--font-size-16);
        line-height: var(--line-height-20);
        font-weight: var(--font-weight-bold);
        max-width: 213px;

        @include to-mobile-max {
          max-width: 100%;
          font-size: var(--font-size-13);
          line-height: var(--line-height-16);
        }
      }
    }

    .benefits-block-img {
      line-height: 0;

      svg {
        width: 71px;
        @include to-tablet-1500 {
          width: 50px;
          height: auto;
        }
        @include to-mobile-max {
          width: 40px;
          height: auto;
        }
      }
    }
  }
}
</style>
