<template>
  <div
    class="checkout-shipping__existing-addresses__address__container"
    :class="
      selected &&
      'checkout-shipping__existing-addresses__address__container__default'
    "
    @click="$emit('select-address', address)"
  >
    <LoadingDots v-if="countries.length === 0 || loadingCountry" />
    <div
      v-else
      class="checkout-shipping__existing-addresses__address tw-flex tw-place-content-end tw-relative tw-pt-9 tw-pr-4 tw-pb-4 tw-pl-4"
    >
      <SfIcon
        icon="cross"
        color="gray"
        size="18px"
        role="button"
        class="tw-flex tw-cursor-pointer tw-absolute tw-top-0 tw-right-0 tw-m-3"
        v-if="canRemoveAddresses && !userAddressesGetters.isDefault(address)"
        @click="
          (e) => {
            e.preventDefault();
            e.stopPropagation();
            removeAddress(address);
          }
        "
      />
      <div
        v-for="(addressDetail, index) in beautifiedAddress"
        :key="`checkout-shipping-existing-addresses-address-${index}`"
        class="checkout-shipping__existing-addresses__address__detail"
      >
        <span
          class="checkout-shipping__existing-addresses__address__detail__label"
          >{{ addressDetail[0] }}</span
        >
        <span
          class="checkout-shipping__existing-addresses__address__detail__info"
          >{{ addressDetail[1] }}</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  onMounted,
  ref,
  computed,
} from '@nuxtjs/composition-api';
import { LoadingDots } from '~/components/General/FormElements';
import {
  useCountrySearch,
  userAddressesGetters,
  useAddresses,
} from '@gemini-vsf/composables';
import { SfIcon } from '@storefront-ui/vue';
import { useUser } from '~/composables';

export default defineComponent({
  name: 'AddressBox',
  components: { LoadingDots, SfIcon },
  props: {
    countries: {
      type: Array,
      default: () => [],
    },
    address: {
      type: Object,
      required: true,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    sharedRefKey: {
      type: String,
      default: 'Step:Shipping-',
    },
    showIsDefault: {
      type: Boolean,
      default: true,
    },
    canRemoveAddresses: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { root }) {
    const { load: loadUser } = useUser();
    const { load, remove } = useAddresses();
    const loadingCountry = ref(true);
    const { search: searchCountry, country } = useCountrySearch(
      `${props.sharedRefKey}${
        props?.address?.country_code || props?.address?.country?.code
      }`
    );

    const removeAddress = (address) => {
      root.$dialog
        .confirm({
          title: 'Sei sicuro di voler cancellare questo indirizzo?',
          okButtonText: 'Si',
          cancelButtonText: 'No',
        })
        .then(async (result) => {
          if (result?.isOk) {
            const isDefault = userAddressesGetters.isDefault(address);
            if (!isDefault) {
              await remove({ address });
              await load({});
              await loadUser();
            }
          }
          return false;
        })
        .catch((error) => {
          console.log('error', error);
        });
    };

    onMounted(async () => {
      const countryCode =
        props?.address?.country_code || props?.address?.country?.code;
      if (countryCode) {
        await searchCountry({
          id: countryCode.toLowerCase(),
        });
      }
      loadingCountry.value = false;
    });

    const beautifiedAddress = computed(() => {
      const { address, showIsDefault } = props;
      return Object.entries({
        'Nome: ': address.firstname,
        'Cognome: ': address.lastname,
        'Via: ': address.street.join(' '),
        'Città: ': address.city,
        'CAP: ': address.postcode,
        'Provincia: ':
          country?.value?.available_regions?.find(
            (region) => region.code === address.region.region_code
          )?.name ||
          address.region?.region_code ||
          address.region?.code,
        'Stato: ': country.value?.full_name_locale,
        'Telefono: ': address.telephone,
        ...(showIsDefault && {
          'Spedizione default: ': address.default_shipping ? 'SI' : 'NO',
        }),
        ...(showIsDefault && {
          'Fatturazione default: ': address.default_billing ? 'SI' : 'NO',
        }),
      });
    });

    return {
      searchCountry,
      loadingCountry,
      country,
      beautifiedAddress,
      removeAddress,
      userAddressesGetters,
    };
  },
});
</script>

<style lang="scss" scoped>
.checkout-shipping__existing-addresses__address {
  &__container {
    min-height: 8rem;
    @include default-outline-black;
    &__default {
      outline-width: 0.1875rem;
      background-color: var(--c-primary);
    }
  }
  @include flex-column;
  @include paragraph-regular;
  justify-content: space-between;
  //padding: 1rem;
  &__detail {
    display: flex;
    justify-content: space-between;
    &__info {
      text-align: right;
    }
    &__label {
      font-weight: bold;
    }
  }
}
</style>
