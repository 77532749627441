<template>
  <div class="category-page__sub-heading">
    <CategoryCounter :category-id="categoryId" />
    <CustomButton
      theme="white"
      @click="toggleFilterSidebar(categoryId)"
      specific-width="fit-content"
    >
      FILTRI
    </CustomButton>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import { CategoryCounter } from '~/components/Category';
import { CustomButton } from '~/components/General/FormElements';
import { useUiState } from '~/composables';

export default defineComponent({
  name: 'CategorySubHeading',
  components: { CustomButton, CategoryCounter },
  props: {
    categoryId: {
      type: String,
      default: '',
    },
  },
  setup() {
    const { toggleFilterSidebar } = useUiState();

    return {
      toggleFilterSidebar,
    };
  },
});
</script>

<style lang="scss" scoped>
.category-page__sub-heading {
  @include flex-center;
  justify-content: space-between;
  border-bottom: 1px solid var(--c-black);
  padding: 1.4375rem 0;
  margin-bottom: 1.5rem;
}
@include from-desktop-min {
  .category-page__sub-heading {
    display: none;
  }
}
</style>
