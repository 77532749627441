<template>
  <div class="full-width" v-if="isCurrentCategoryMatched">
    <SfLoader v-if="isLoading" :loading="isLoading" />
    <ProductSlider
      v-else
      class="container"
      :products="products"
      :title="$t('Prodotti correlati')"
      slider-id="related-products"
    />
  </div>
</template>

<script>
import {
  defineComponent,
  ref,
  useFetch,
  shallowRef,
} from '@nuxtjs/composition-api';
import { contentGetters, useContent, useFacet } from '@gemini-vsf/composables';
import { useI18n } from '~/helpers/hooks/usei18n';
import { useUser, useUiHelpers } from '~/composables';
import ProductSlider from '~/components/ProductSlider.vue';
import { SfLoader } from '@storefront-ui/vue';

export default defineComponent({
  name: 'RelatedProducts',
  components: {
    ProductSlider,
    SfLoader,
  },
  props: {
    categoryId: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const { locale } = useI18n();
    const isLoading = ref(false);
    const { isAuthenticated } = useUser();
    const uiHelpers = useUiHelpers();
    const filtersStr = JSON.stringify(uiHelpers.getFacetsFromURL());
    const searchSpecsStr = `facetId::${filtersStr}`;
    const isCurrentCategoryMatched = ref(false);
    const { result, search } = useFacet(searchSpecsStr);

    const products = ref([]);
    const { blocks, loadBlocks } = useContent(
      `plp-products-related-collection-${locale.includes('it') ? 'it' : 'en'}`
    );

    const relatedProductsSelector = shallowRef(
      `plp-products-related-collection-${locale.includes('it') ? 'it' : 'en'}`
    );
    const relatedProductsData = ref();
    const sliderKey = ref(0);

    useFetch(async () => {
      await loadBlocks({ identifiers: [relatedProductsSelector.value] });
      isLoading.value = true;
      try {
        relatedProductsData.value = contentGetters.getCmsBlockContent(
          blocks.value
        )[relatedProductsSelector.value];
        isCurrentCategoryMatched.value =
          Object.keys(relatedProductsData.value.data)?.includes(
            props.categoryId
          ) || false;
      } catch {
        console.error(
          `Unable to fetch block content - ${relatedProductsSelector.value}`
        );
      }
      if (isCurrentCategoryMatched.value) {
        const indexFound = Object.keys(relatedProductsData.value.data).indexOf(
          props.categoryId
        );
        const categoryUid = ref('');
        if (indexFound > -1)
          categoryUid.value = Object.values(relatedProductsData.value.data)[
            indexFound
          ];
        if (categoryUid.value) {
          await search({
            ...uiHelpers.getFacetsFromURL(),
            categoryId: `grn:product-list:product-list::${categoryUid.value}`,
            customQuery: {
              products: 'productListCustom',
            },
          });
        }
        products.value = result?.value?.data?.items || [];
      }
      sliderKey.value += 1;
      isLoading.value = false;
    });

    return {
      isAuthenticated,
      isCurrentCategoryMatched,
      products,
      isLoading,
      sliderKey,
    };
  },
});
</script>

<style lang="scss">
.category-page__related-products {
  max-width: 100%;
  .sf-carousel {
    width: calc(100vw - 2rem) !important;
    &-item,
    .glide__slide {
      height: auto !important;
    }
    &__controls {
      display: flex;
      width: 105%;
      left: -2.5%;
      z-index: 2;
      @include pointer;
    }
  }
  &__title {
    @include text-24x24-bold-italic;
    margin-bottom: 2.5rem;
  }
  &__navigation {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    background-color: var(--c-primary);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    @include flex-center;
    @include text-36x40-black;
  }
}
@include from-desktop-min {
  .category-page__related-products {
    .sf-carousel {
      width: calc(100% - 2rem) !important;
      &-item,
      .glide__slide {
        max-width: calc(100% / 6 - 2rem) !important;
      }
      &__controls {
        width: 103% !important;
        left: -1.5% !important;
      }
      &__wrapper {
        margin: 0 !important;
        max-width: unset !important;
      }
      &__slides {
        gap: 1.5rem;
      }
    }
    &__navigation {
      width: 3rem;
      height: 3rem;
      @include text-36x40-black;
    }
  }
}
</style>
