<template>
  <div class="category-top">
    <div class="category-top-left">
      <h1 v-if="categoryData && !loadingCategoryData">
        {{ categoryData.name }}
      </h1>
      <SfSkeleton v-else />

      <h2 v-if="categoryData && !loadingCategoryData" v-show="false">
        {{ categoryData.description }}
      </h2>
      <p v-if="categoryData && !loadingCategoryData">
        {{ categoryData.description }}
      </p>
      <SfSkeleton v-else />

      <picture
        class="category-icon block"
        v-if="categoryImageDesktop && categoryImageMobile"
      >
        <source media="(min-width: 767px)" :srcset="categoryImageDesktop" />
        <img
          :src="categoryImageMobile"
          class="w-auto h-auto"
          :alt="categoryImageAlt"
        />
      </picture>
    </div>
    <div
      class="category-top-right"
      @click="toggleLoginAndRegisterModal"
      v-if="!isAuthenticated"
    >
      <nuxt-img src="/icons/login-icon.svg" alt="Banner" />
      <div class="category-top-right__title">
        {{ $t('Non vedi i prezzi?') }}
      </div>
      <p>
        {{ $t('Accedi al tuo account oppure registrati.') }}
      </p>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  useContext,
  useFetch,
  shallowRef,
} from '@nuxtjs/composition-api';
import { useCategoryPage, useUiState } from '~/composables';
import { SfSkeleton } from '@storefront-ui/vue';
import { useContent, contentGetters } from '@gemini-vsf/composables';

export default defineComponent({
  name: 'CategoryHeading',
  components: { SfSkeleton },
  props: {
    categoryId: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const {
      app: { $cookies },
    } = useContext();
    const isAuthenticated = $cookies.get('vsf-customer');
    const { categoryData, loadingCategoryData } = useCategoryPage(
      props.categoryId
    );
    const { toggleLoginAndRegisterModal } = useUiState();
    const { blocks, loadBlocks } = useContent(`plp-image-collection-it`);
    const categoryImageSelector = shallowRef(`plp-image-collection-it`);
    const categoryImageDesktop = shallowRef();
    const categoryImageMobile = shallowRef();
    const categoryImageAlt = shallowRef();

    useFetch(async () => {
      await loadBlocks({ identifiers: [categoryImageSelector.value] });
      try {
        const blockData = contentGetters.getCmsBlockContent(blocks.value);
        categoryImageDesktop.value =
          blockData?.[categoryImageSelector.value]?.data?.[
            props.categoryId
          ]?.image?.desktop;
        categoryImageMobile.value =
          blockData?.[categoryImageSelector.value]?.data?.[
            props.categoryId
          ]?.image?.mobile;
        categoryImageAlt.value =
          blockData?.[categoryImageSelector.value]?.data?.[props.categoryId]
            ?.image?.alt ||
          categoryData.value?.name ||
          'category image';
      } catch (e) {
        console.warn('Unable to fetch block content.', e);
      }
    });

    return {
      isAuthenticated,
      categoryData,
      loadingCategoryData,
      categoryImageDesktop,
      categoryImageMobile,
      categoryImageAlt,
      toggleLoginAndRegisterModal,
    };
  },
});
</script>

<style lang="scss" scoped>
.category-top {
  display: flex;
  margin-bottom: 2.5rem;
  padding-bottom: 2.5rem;
  align-items: flex-start;
  @include to-mobile-max {
    flex-direction: column;
    margin-bottom: 2rem;
  }
  .category-top-left {
    max-width: 89.9375rem;
    padding-right: 1rem;
    img {
      max-width: 5rem;
      max-height: 5rem;
    }
    @include to-mobile-max {
      padding-right: 0;
      display: var(--flex);
      flex-direction: column;
      .category-icon {
        order: 2;
        margin-bottom: 1.75rem;
        img {
          max-width: 2.5rem;
        }
      }
    }
    h1 {
      font-size: var(--font-size-44);
      line-height: var(--line-height-48);
      margin-bottom: 2.5rem;
      font-family: var(--lato-medium);
      @include to-mobile-max {
        padding-right: 0;
        font-size: var(--font-size-24);
        line-height: var(--line-height-24);
        margin-bottom: 1.75rem;
      }
    }
    p {
      margin-bottom: 1.5rem;
      @include to-mobile-max {
        font-size: var(--font-size-13);
        line-height: var(--line-height-16);
        margin-bottom: 0;
        order: 3;
      }
    }
  }
  .category-top-right {
    min-width: 13rem;
    max-width: 13rem;
    background: var(--c-yellow);
    text-align: center;
    padding: 0.75rem;
    align-items: flex-start;
    margin: 2.938rem 0 0 auto;
    cursor: var(--cursor-pointer);
    @include to-mobile-max {
      max-width: 100%;
      min-width: 100%;
      margin-top: 1.625rem;
    }
    &__title {
      font-size: var(--font-size-16);
      line-height: var(--line-height-18);
      color: var(--c-dark-grey);
      font-weight: var(--font-weight-medium, normal);
      font-family: var(--lato-medium);
      padding: 0;
      margin: 0 0 20px 0;
    }
    p {
      margin: 0;
    }
  }
}
</style>
